<template>
  <div>
    <GenericForm
      title="tipo de esterilização"
      previousRoute="tipoEsterilizacao"
      editRoute="tipoEsterilizacaoEditar"
      :model="model"
      :route="tipoEsterilizacaoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_tipo_esterilizacao"
      :permissionsToWrite="['rw_esterilizacao']"
      :permissionsToEdit="['re_esterilizacao']"
      :permissionsToDelete="['rd_esterilizacao']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nome*" label-for="nome">
              <b-form-input
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                name="nome"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group
              label="Tempo de esterilização (em minutos)"
              label-for="tempoEsterilizacao"
              label-class="float-left"
            >
              <info-icon
                v-b-tooltip.hover.right="
                  'Insira em minutos o tempo de duração do processamento cadastrado'
                "
                class="tooltip-icon"
              />
              <b-form-input
                id="tempoEsterilizacao"
                v-model="model.tempoEsterilizacao"
                autocomplete="off"
                type="number"
                class="invision-input"
                name="tempoEsterilizacao"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('tempoEsterilizacao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>

import GenericForm from '@/components/Form/GenericForm';

export default {
  components: {
    GenericForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_tipo_esterilizacao: this.$route.params.id || -1,
        nome: '',
        descricao: '',
        tempoEsterilizacao: null,
      },

      tipoEsterilizacaoRoute: 'tipoEsterilizacao',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },
  },
};
</script>
